import React, { useState } from "react"
import { loadStripe } from '@stripe/stripe-js';

const buttonStyles = {
    fontSize: "13px",
    textAlign: "center",
    color: "#000",
    padding: "12px 60px",
    boxShadow: "2px 5px 10px rgba(0,0,0,.1)",
    backgroundColor: "rgb(255, 178, 56)",
    borderRadius: "6px",
    letterSpacing: "1.5px",
}
const buttonDisabledStyles = {
    opacity: "0.5",
    cursor: "not-allowed",
}
let stripePromise
const getStripe = () => {
    if (!stripePromise) {
        stripePromise = loadStripe("pk_live_51H3WNjIVYFiFtn3tkBnNfQIJJYJDmYgq8wR8RjV8bjtI03ZvqLXZp0E6kLzy6u9JAfl3L7P8wRBb8da7MTQAVcTC00QhmAF1zG")
    }
    return stripePromise
}
const Checkout = () => {
    const [loading, setLoading] = useState(false)
    const redirectToCheckout = async event => {
        event.preventDefault()
        setLoading(true)
        const stripe = await getStripe()
        const { error } = await stripe.redirectToCheckout({
            mode: "payment",
            shippingAddressCollection: {
                allowedCountries: ['US', 'CA'],
            },
            lineItems: [{ price: "price_1HI2H8IVYFiFtn3thIqO3wBd", quantity: 1 }],
            successUrl: `https://psychoactivewear.com`,
            cancelUrl: `https://psychoactivewear.com/shop/`,
        })
        if (error) {
            console.warn("Error:", error)
            setLoading(false)
        }
    }
    return (
        <button
            disabled={loading}
            style={
                loading ? { ...buttonStyles, ...buttonDisabledStyles } : buttonStyles
            }
            onClick={redirectToCheckout}
        >
            BUY
        </button>
    )
}
export default Checkout