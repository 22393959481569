import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ShopCard from "../components/shop/shopCard"
import HatImage from "../images/shop/Psycho-Active-Logo-Hat-Web-transparent.png"
import TeeImage from "../images/shop/Psycho-Active-Logo-tshirt-Web-Transparent.png"

const ShopPage = () => (
    <Layout>
        <SEO title="Shop" />
        <h1 hidden>Shop</h1>

        <div className="shop-card--wrapper">
            <ShopCard
                title="5 Panel Hat"
                description=""
                heroImage={HatImage}
                id="prod_HrKVgdri2ULFR9"
                price="35"
                quantity="1"
            />
            <ShopCard
                title="Tee"
                description=""
                heroImage={TeeImage}
            />
        </div>
    </Layout>
)

export default ShopPage
