import React from 'react'
import QuickCheckout from './quickCheckout';

export default function ShopCard(props) {
    function MyCustomButton() {
        return (
            <button>Buy</button>
        )
    }

    return (
        <div className="shop-card--container">
            <img src={props.heroImage} alt={props.title} className="shop-card--image" />
            <h2>{props.title}</h2>
            <p>{props.description}</p>
            {/* <button>Sold Out</button> */}
            <QuickCheckout />
        </div>
    )
}
